import { createHttpEndpoint } from '../../utils'

import type { Article } from './article'
import { type ArticlesHubFooter } from './footer'
import { type ArticlesHub } from './hub'

/**
 * Get the Hub Page Infos
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buying-guide-api/definition#operation/getBrandingBuyingHubsCartById
 *
 * TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
 */
export const getHubPageInfos = createHttpEndpoint<ArticlesHub>({
  method: 'GET',
  operationId: 'buyingGuideHubFetch',
  path: '/bm/branding/buying-guide/hubs/:id',
  transformResponseToCamelCase: true,
})

export const getRootHubPageInfos = createHttpEndpoint<ArticlesHub>({
  method: 'GET',
  operationId: 'buyingGuideRootHubFetch',
  path: '/bm/branding/buying-guide/hubs',
  transformResponseToCamelCase: true,
})

/**
 * Get child hub links by category for the navigation footer
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buying-guide-api/definition#operation/getFooterForBuyingGuideHubsCatById}
 *
 * TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
 */
export const getHubFooterLinks = createHttpEndpoint<ArticlesHubFooter>({
  method: 'GET',
  operationId: 'buyingGuideHubFooterLinks',
  path: '/bm/branding/buying-guide/hubs/:id/footer',
  transformResponseToCamelCase: true,
})

/**
 * Get child hub links by category of the home category for the navigation footer
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buying-guide-api/definition#operation/getFooterForBuyingGuideHomeHubsCatById}
 *
 * TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
 */
export const getRootHubFooterLinks = createHttpEndpoint<ArticlesHubFooter>({
  method: 'GET',
  operationId: 'buyingGuideRootHubFooterLinks',
  path: '/bm/branding/buying-guide/hubs/footer',
  transformResponseToCamelCase: true,
})

/**
 * Get the Buying guide Page infos
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buying-guide-api/definition#operation/getBuyingGuideContentPageById}
 *
 * TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
 */
export const getBuyingGuidePageInfos = createHttpEndpoint<ArticlesHub>({
  method: 'GET',
  operationId: 'buyingGuideDetailsFetch',
  path: '/bm/branding/buying-guide/pages/:slug',
  transformResponseToCamelCase: true,
})

/**
 * TODO Add link to API specs when available
 * TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
 */
export const postBuyingGuideVote = createHttpEndpoint({
  method: 'POST',
  operationId: 'buyingGuideVote',
  path: '/bm/branding/buying-guide/pages/:id/:value',
  transformResponseToCamelCase: true,
})

/**
 * BuyingGuideLandingAPIView
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/buying-guide-api/definition#operation/getBuyingGuideByLandingPageId}
 *
 * TODO [GNL-2103] remove endpoint when buying guides are migrated to CMS
 */
export const getPLPBuyingGuides = createHttpEndpoint<Article[]>({
  method: 'GET',
  operationId: 'fetchLandingBuyingGuides',
  path: '/bm/branding/internal/articles/landing-pages/:id',
})

/**
 * internal-linking API
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/internal-linking-api/definition#/cards/get_card_links}
 *
 */
export const getInternalCards = createHttpEndpoint<{
  linkLists: Array<{
    listId: string
    links: Array<{
      link: {
        href: string
        relativeHref: string
      }
      image: {
        src: string | null
        srcMobile: string | null
        alt: string | null
      }
      title: string | null
      description: string | null
      tagline: string | null
    }>
  }>
}>({
  method: 'GET',
  operationId: 'fetchInternalCards',
  path: '/internal-links/cards',
})
