<template>
  <ArticleCardsCarousel :articles :title="i18n(translations.title)" />
</template>

<script setup lang="ts">
import ArticleCardsCarousel from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCardsCarousel.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { useArticles } from '~/scopes/product-list/composables/useArticles'

import translations from './ArticlesCarousel.translations'

const i18n = useI18n()
const { articles, fetchArticles } = useArticles()
fetchArticles()
</script>
