import { useRequestURL } from '#imports'
import { ref } from 'vue'

import { getInternalCards } from '@backmarket/http-api/src/api-specs-acquisition/buying-guide/buying-guide'
import type { ArticleCard } from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCard.types'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export function useArticles() {
  const articles = ref<ArticleCard[]>([])
  const { href: currentLocation } = useRequestURL()

  async function fetchArticles(href = currentLocation) {
    const { data } = await useHttpFetch(getInternalCards, {
      queryParams: {
        source_url: href,
      },
    })

    articles.value = data.value
      ? data.value.linkLists[0].links
          .map((item) => ({
            title: item.title,
            text: item.description,
            id: item.link.href,
            imageSrc: item.image.src,
            imageAlt: item.image.alt ?? item.title,
            link: { href: item.link.relativeHref },
            category: item.tagline,
          }))
          .filter(
            (item): item is ArticleCard =>
              item.imageSrc !== null &&
              item.title !== null &&
              item.text !== null &&
              item.imageAlt !== null,
          )
      : []
  }

  return { articles, fetchArticles }
}
